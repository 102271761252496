.noti-icon .badge {
  left: 23px;
}
.mt-2 {
  margin-top: 20px !important;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid #eeeef5;
  height: 239px;
}
.dz-message {
  text-align: center;
  padding: 100px;
}
.fcYNFc {
  background-color: #ffffff !important;
}
.task-box {
  border: 1px solid #e1e1e6;
}
.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.fc-event .fc-content {
  padding: 5px;
  color: #fff;
}
.chat-conversation .right .conversation-list {
  margin-right: 15px;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #7cb342 !important;
}

/* Custom Added */

.topnav {
  background-color: #6D19BB !important;
}

.topnav .navbar-nav .nav-item .nav-link.active {
  color: #ffffff;
}

.topnav .navbar-nav .nav-item .nav-link:hover {
  color: #ffffff;
}
.topnav .navbar-nav .dropdown.active > a {
  color: #ffffff;
}

.topnav .dropdown .dropdown-item.active, .topnav .dropdown .dropdown-item:active {
  color: #ffffff;
}


.navbar-brand-box {
  display: unset; 
}

/* Tables */

.thead-light {
  color:#ffffff;
}

tr.fixedwidth th {
  width: 125px;
}